import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
function importedRouteComponent(source) {
    const Lazy = React.lazy(source);
    return () => (_jsx(React.Suspense, Object.assign({ fallback: _jsx(_Fragment, { children: "loading..." }, void 0) }, { children: _jsx(Lazy, {}, void 0) }), void 0));
}
export default function App() {
    return (_jsx(_Fragment, { children: _jsx(Router, { children: _jsxs(Switch, { children: [_jsx(Route, { path: "/", exact: true, component: importedRouteComponent(() => import(/* webpackChunkName: "IndexPage" */ './IndexPage')) }, void 0), _jsx(Route, { path: "/contact", exact: true, component: importedRouteComponent(() => import(/* webpackChunkName: "ContactPage" */ './ContactPage')) }, void 0), _jsx(Route, { path: "/privacyPolicy", exact: true, component: importedRouteComponent(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ './PrivacyPolicyPage')) }, void 0), _jsx(Route, { path: "/termsAndConditions", exact: true, component: importedRouteComponent(() => import(/* webpackChunkName: "TermsAndConditionsPage" */ './TermsAndConditionsPage')) }, void 0), _jsx(Route, Object.assign({ path: "*" }, { children: _jsx("h1", { children: "not found" }, void 0) }), void 0)] }, void 0) }, void 0) }, void 0));
}
