import { jsx as _jsx } from "react/jsx-runtime";
import 'modern-css-reset';
import ReactDOM from 'react-dom';
import App from './App';
(() => {
    const root = document.createElement('div');
    root.id = 'root';
    root.style.minWidth = '100vw';
    root.style.minHeight = '100vh';
    document.querySelector('body').appendChild(root);
    ReactDOM.render(_jsx(App, {}, void 0), root);
})();
